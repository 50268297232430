@import "../../assets/styles/colors";

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 13, 35, 0.9);
    touch-action: none;
    overscroll-behavior: none;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;

    &__content {
        background-color: $color-secondary-violet;
        padding: 64px;
        width: 60%;
        border-radius: 9px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;


        @media (max-width: 996px) {
            width: 70%;
            padding: 40px;

            h3 {
                font-size: 16px;
            }
        }

        @media (max-width: 576px) {
            width: 90%;
            padding: 40px 12px;

            h3 {
                font-size: 12px;
            }
        }
    }

    &__close-icon-wrapper {
        display: flex;
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        & svg {
            width: 23px;
            height: 23px;

            @media (max-width: 576px) {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__actions-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 40px;

        @media (max-width: 576px) {
            padding-top: 24px;
            flex-direction: column-reverse;
        }
    }

    &__title {
        @media (max-width: 576px) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    
    &__text {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin-top: 8px;
    
        @media (max-width: 576px) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    & button:nth-child(2n + 2) {
        margin-left: 80px;

        @media (max-width: 996px) {
            margin-left: 20px;
        }

        @media (max-width: 576px) {
            margin-left: 0;
            margin-bottom: 5px;
        }
    }



    & button:only-child {
        width: 289px;

        @media (max-width: 576px) {
            width: 100%;
            
        }
    }
}